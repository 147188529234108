import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { DATA_FIELD } from '../extension/constant'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import Pagination from './pagination.js'
import * as _ from 'lodash'
import ColSelector from './col-selector'

const ResultsDiv = styled.div`
  width: 100%;
  overflow: hidden;
`

const StyledContextMenu = styled(ContextMenu)`
  min-height: 100px;
  min-width: 160px;
  background: white;
  border: lightgrey 0.5px solid;
  border-radius: 5px;
  //padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`
const Cell = styled.div`
  padding: 0;
  display: block;
  height: 100%;
  width: 100%;
`
const ResultHeaderDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  max-height: 46px;
  text-align: left;
  
  button {
    align-self: center;
    height: 34px;
    min-width: 64px;
    border: none;
    border-radius: 1px;
    background: rgba(0,0,0,0.1);
  }
`

/**
 * Results component
 */
class Results extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      stage: null,
      file: null,
      isInitDbModalOpen: false,
      visibleFields: new Set(DATA_FIELD)
    }
  }

  getCell = () => {
    const {isAuthenticated} = this.props
    return isAuthenticated
      ? ({value, original}) => <ContextMenuTrigger key={original._id} id={original._id} style={{width: '100%', height: '100%'}}>
        {value ? <Cell>{value}</Cell> : <Cell></Cell>}
      </ContextMenuTrigger>
      : ({value}) => <Cell>{value}</Cell>
  }

  getColumns = () => DATA_FIELD.map(eachField => {
    return {
      Header: eachField,
      accessor: `_source.${eachField}`,
      Cell: this.getCell()
    }
  })

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return !_.isEqual(this.props.data, nextProps.data)
      || this.props.isAuthenticated !== nextProps.isAuthenticated
      || this.props.count !== nextProps.count
      || !_.isEqual(this.state.visibleFields, nextState.visibleFields)
  }

  renderResultHeader = () => {
    const {count, dsl, clearSearch, createNewEntry, isAuthenticated, setInitDbModal} = this.props
    return <ResultHeaderDiv>
      <div>
        <p>
          {count} results were found. <a target="_blank" onClick={clearSearch}>Clear search</a>
          {count !== 0 &&
          <> <br/>Alternatively, you can <a target="_blank" href={'/api/v1/data/file?' + dsl}>download</a> all matched results in a csv file.</>}
        </p>
      </div>
      <div>
        {isAuthenticated && <button onClick={createNewEntry}>Create</button>}
        {isAuthenticated && <button style={{marginLeft: 5}} onClick={setInitDbModal}>Reload Database</button>}
      </div>
      <ColSelector
        onApply={visibleFields => this.setState({visibleFields})}
        visibleFields={this.state.visibleFields}
      />
    </ResultHeaderDiv>
  }

  render () {
    const {data, editAnEntry, removeEntry, isAuthenticated, pagination} = this.props

    // Filter columns
    let columns = this.getColumns()
    columns = columns.filter(({Header}) => this.state.visibleFields.has(Header))
    if (_.isEmpty(columns)) {
      columns = [{Header: '< No columns selected >'}]
    }

    return <ResultsDiv>
      {this.renderResultHeader()}
      <ReactTable
        PaginationComponent={() => <Pagination pagination={pagination}/>}
        data={data}
        manul
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={20}
        renderCurrentPage={(v) => <div>renderCurrentPage</div>}
        previousText={'Previous'}
        nextText={'Next'}
      />
      {isAuthenticated
      && data.map(eachRow => {
        const id = eachRow._id
        return <StyledContextMenu id={id} key={id}>
          <MenuItem onClick={() => editAnEntry(id)}>
            <span className="bp3-icon-standard bp3-icon-edit">  Edit </span>
          </MenuItem>
          <MenuItem onClick={() => removeEntry(id)}>
            <span className="bp3-icon-standard bp3-icon-trash">  Remove </span>
          </MenuItem>
          <MenuItem onClick={editAnEntry}>
            <span className="bp3-icon-standard bp3-icon-add">  Add..</span>
          </MenuItem>
        </StyledContextMenu>
      })
      }

    </ResultsDiv>

  }
}

Results.propTypes = {
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  clearSearch: PropTypes.func.isRequired,
  editAnEntry: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  createNewEntry: PropTypes.func.isRequired,
  removeEntry: PropTypes.func.isRequired,
  setInitDbModal: PropTypes.func.isRequired
}

export default React.memo(Results)
