import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  text-align: center;
`

export default () => {
  /* eslint-disable no-undef */
  return <StyledFooter>
    <span>© 2018 University of Melbourne (v{VERSION}-sha1:{LAST_COMMIT_HASH})</span>
  </StyledFooter>
}
