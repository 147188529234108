import React, { useState } from 'react'
import styled from 'styled-components'
import { InputGroup, Button } from '@blueprintjs/core'
import cogoToast from 'cogo-toast'

const LoginFormDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 300px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
`
const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 12px;
`
const StyledButton = styled(Button)`
  width: 176px;
`
/**
 * Layout component
 */
const UpdatePasswordForm = ({onUpdatePassword}) => {

  const [username, setusername] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const validateForm = () => {
    if ([password, newPassword, confirmNewPassword].some((str) => str.length === 0)) {
      cogoToast.warn("Please fill the form.")
    } else if (password === newPassword) {
      cogoToast.warn("New password should be different from the old one!")
      return false
    } else if (newPassword !== confirmNewPassword) {
      cogoToast.warn("Two new passwords are not the same!")
    } else {
      return true
    }
  }
  /**
   * Handle udpate password
   */
  const handleUpdatePassword = () => {
    if (validateForm()) {
      onUpdatePassword({username, password, newPassword, confirmNewPassword})
    }
  }

  return <LoginFormDiv>
    <h2>
      Update password
    </h2>
    <StyledInputGroup id="username" placeholder="username" value={username} onChange={evt => setusername(evt.target.value)}/>
    <StyledInputGroup id="password" type="password" placeholder="Password" value={password} onChange={evt => setPassword(evt.target.value)}/>
    <StyledInputGroup
      id="new-password"
      type="password"
      placeholder="New password"
      value={newPassword}
      onChange={evt => setNewPassword(evt.target.value)}
    />
    <StyledInputGroup
      id="confirm-new-password"
      type="password"
      placeholder="Confirm new password"
      value={confirmNewPassword}
      onChange={evt => setConfirmNewPassword(evt.target.value)}
    />
    <StyledButton type="button" onClick={handleUpdatePassword}>Update</StyledButton>
  </LoginFormDiv>
}

export default UpdatePasswordForm
