import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ControlGroup, HTMLSelect, InputGroup, Button } from '@blueprintjs/core'
import { SEARCH_FIELD } from '../extension/constant'
import * as _ from 'lodash'

const StyledControlGroup = styled(ControlGroup)`
  margin-bottom: 8px;
`

/**
 * @param {Object} props
 * @return {ReactFunctionalComponent} Search Form component
 */
const SearchForm = ({...props}) => {
  const {addField, updateField, form, handleSearch, resetFields} = props
  return (
    <React.Fragment>
      {form.map((eachField, idx) => {
        const {fieldName, value} = eachField
        const availableField = _.concat(
          SEARCH_FIELD
            .filter(field =>
              field === '---'
              || field === fieldName
              || form.map(formValue => formValue.fieldName).indexOf(field) < 0
            )
        )
        return <StyledControlGroup fill={true} key={idx}>
          <HTMLSelect
            name="fieldName"
            options={availableField}
            value={fieldName}
            onChange={evt => updateField(idx, {fieldName: evt.target.value, value: value})}
          >
            {fieldName}
          </HTMLSelect>
          <InputGroup
            placeholder="Find filters..."
            value={value}
            onChange={evt => updateField(idx, {fieldName, value: evt.target.value})}
          />
        </StyledControlGroup>
      })}
      <Button style={{marginTop: 24}} onClick={addField} type="button" fill={true}>Add field</Button>
      <Button onClick={resetFields} type="button" fill={true}>Reset field</Button>
      <Button onClick={handleSearch} type="button" fill={true}>Search</Button>
    </React.Fragment>
  )
}

SearchForm.propTypes = {
  addField: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired
}

export default SearchForm
