import React from 'react'
import './App.css'
import Layout from './containers/layout'

/**
 * Application function.
 * @return {ReactComponent} React Component
 */
function App() {
  return (
    <div className="App">
      <Layout/>
    </div>
  )
}

export default App
