import React from 'react'
import { FileInput, ProgressBar, Tooltip } from '@blueprintjs/core'
import Papa from 'papaparse'
import db from '../dexie'
import axios from 'axios'
import styled from 'styled-components'
import cogoToast from 'cogo-toast'
import { DATA_FIELD } from '../extension/constant'
import * as _ from 'lodash'
const CancelToken = axios.CancelToken
const source = CancelToken.source()

const Container = styled.div`
  min-height: 100px;
  width: 500px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px 16px;
  
  p {
    padding: 5px 40px;
  }
`

const ProgressRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  min-height: 8px;
  
  label {
    display: flex;
    width: 25%;
    min-width: 18px;
  }
  
  .progressBar {
    width: 75%;
  }
  
`

class DbInit extends React.Component {

  initialState = {
    csvParsed: false, // is csv filed loaded
    count: null, // number of csv row to be pushed
    isParsingStart: false,
    isParsingCsv: false,
    length: null,
    isPostingCsvRows: false,
    progress: 0,
    parsingProgress: 0,
    postingProgress: 0,
    postingFinished: false,
  }

  constructor (props) {
    super(props)
    this.state = this.initialState
  }

  /**
   * Request to drop the ageing analytics collection.
   * @return {Promise<void>}
   */
  xhrRequestDropCollection = async () => {
    try {
      const res = await axios.delete('/api/v1/ageing-analytics-db/core-collection')
      if (res.data.success) {
        return true
      } else {
        cogoToast.error('Failed to drop collection')
        throw 'Failed to drop collection'
      }
    } catch (e) {
      console.warn(e)
      throw e
    }
  }
  /**
   * Reqeust to drop elastic search index.
   * @return {Promise<boolean>}
   */
  xhrRequestDropElasticIndex = async () => {
    try {
      const res = await axios.delete('/api/v1/ageing-analytics-db/core-collection/index')
      if (res.data.success) {
        return true
      } else {
        cogoToast.error('Failed to drop indexes')
        throw 'Failed to drop indexes'
      }
    } catch (e) {
      console.warn(e)
      throw e
    }
  }
  /**
   * Promise wrapper for setState
   * @param state
   */
  setStatePromise = (state) => {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }
  /**
   * Post csv to server.
   * @return {Promise<void>}
   */
  xhrRequestPostNewEntries = async () => {
    await this.xhrRequestDropCollection()
    await this.setStatePromise({isPostingCsvRows: true})
    let currentPage = 1
    const pageSize = 160
    const maxPage = Math.ceil(this.state.count / pageSize)
    while (currentPage <= maxPage) {
      const data = await db.csvRow.offset((currentPage - 1) * pageSize).limit(pageSize).toArray()
      const dataInChunks = _.chunk(data, 4)
      await Promise.all(dataInChunks.map(batch => axios.post('/api/v1/ageing-analytics-db/core-collection', batch, {cancelToken: source.token})))
      // await axios.post('/api/v1/ageing-analytics-db/core-collection', data)
      this.setState({postingProgress: currentPage / maxPage})
      currentPage++
    }
    await this.setStatePromise({postingFinished: true})
  }

  componentWillUnmount () {
    source.cancel('Operation canceled by the user.')
    db.csvRow.clear()
  }

  /**
   * Handle reading file in the client side.
   * @param evt
   */
  handleReadingFile = (evt) => {
    const file = evt.target.files[0]
    if (!file) return null
    if (file.type !== 'text/csv' && file.type !== 'text/plain' && file.type !== 'application/vnd.ms-excel') {
      cogoToast.warn('The file you upload is not a csv file.')
    } else {
      const fileSize = file.size
      this.setState({isParsingCsv: true, isParsingStart: true})
      Papa.LocalChunkSize = 1024 * 512 // read 0.5 mb each time
      const totalChunk = fileSize / (1024 * 512)
      let chunkCounter = 0
      /**
       * Check if the fields are expected.
       * @param fields
       */
      const isFieldHeadMatch = (fields) => {
        return fields.length === DATA_FIELD.length &&
          _.isEqual(_.sortBy(DATA_FIELD), _.sortBy(fields))
      }
      Papa.parse(file, {
        delimiter: ',',
        newline: '',
        quoteChar: '"',
        encoding: "utf8",
        header: true,
        // worker: true,
        chunk: async (step, parser) => {
          if (!isFieldHeadMatch(step.meta.fields)) {
            // does not match fields. abort.
            parser.abort()
            cogoToast.warn('The headline of the csv does not meet the requirement.', {hideAfter: 7})
            this.setState({...this.initialState})
          } else {
            // match
            parser.pause()
            try {
              await db.csvRow
                .bulkAdd(step.data)
            } catch (e) {
              console.warn(e)
            }
            this.setState({parsingProgress: chunkCounter / totalChunk})
            chunkCounter++
            parser.resume()

          }
        },
        complete: () => {
          db.csvRow.count((count) => {
            this.setState({count, csvParsed: true, isParsingCsv: false})
          })
        },
        error: (error) => {console.warn(error)}
      })
    }
  }

  componentWillMount () {
    // Clear table in IndexDb
    db.csvRow.clear()
  }

  /**
   * Render parsing page
   * @return {*}
   */
  renderParsing = () => {
    const {csvParsed, isParsingStart, parsingProgress} = this.state
    if (isParsingStart && !csvParsed) {
      return <div>
        <div>
          <h3>Please wait while we are parsing your uploaded file.</h3>
        </div>
        <ProgressRow>
          <label>Parsing:</label>
          <ProgressBar className={'progressBar'} intent={'primary'} value={parsingProgress}/>
        </ProgressRow>
      </div>
    } else {
      return null
    }
  }
  /**
   * Render a tool tip span that contains descriptions of the expected CSV format.
   * @return {*}
   */
  renderToolTipSpan = () => {
    const CsvInstruction =
      <div>
        <h4>The csv should follow the formats below:</h4>
        <ul>
          <li>CSV uses comma as delimiter</li>
          <li>CSV uses \r\n as line breaker</li>
          <li>CSV field must not be enclosed by quotations.</li>
        </ul>
        <h4>Besides, the CSV is expected to consist of the following names:</h4>
        <ul style={{columns: 3}}>
          {DATA_FIELD.map(field => <li key={{field}}>{field}</li>)}
        </ul>
      </div>

    return <Tooltip content={CsvInstruction}>
      <span className="bp3-popover-wrapper bp3-tooltip-indicator">
        <span className="bp3-popover-target">
          <span className="" tabIndex="0">CSV</span></span>
        <div className="bp3-overlay bp3-overlay-inline"></div>
      </span>
    </Tooltip>

  }
  /**
   * Render the file upload section.
   * @return {*}
   */
  renderFileUpload = () => {
    if (!this.state.isParsingStart && !this.state.csvParsed) {
      return <>
        <div>
          <h3 style={{textAlign: 'center'}}>By following this wizard, you will <strong>replace </strong>
            the database by an {this.renderToolTipSpan()} file.</h3>
        </div>
        <FileInput
          style={{width: '80%'}}
          type="file"
          text="Choose file..."
          onInputChange={this.handleReadingFile}
        />
      </>
    } else {
      return null
    }
  }
  /**
   * Render parsing result.
   * @return {*}
   */
  renderParsingResult = () => {
    const {csvParsed, isPostingCsvRows} = this.state
    if (csvParsed && !isPostingCsvRows) {
      return <>
        <h3>{`${this.state.count} rows has been parsed.`}</h3>
        <p>{`Are you sure to replace the current database with the new csv data source? Click confirm to proceed.`}</p>
        <div>
          <button className="bp3-button bp3-intent-primary bp3-fill" onClick={this.xhrRequestPostNewEntries}>Confirm</button>
        </div>
      </>
    } else {
      return null
    }
  }
  /**
   * Render the csv posting section.
   * @return {*}
   */
  renderCSVPosting = () => {
    const {csvParsed, isPostingCsvRows, postingProgress, postingFinished} = this.state
    if (csvParsed && isPostingCsvRows && !postingFinished) {
      return <div>
        <h3>Please wait while your database is being updated.</h3>
        <ProgressRow>
          <label>Uploading:</label>
          <ProgressBar className={'progressBar'} intent={'primary'} value={postingProgress}/>
        </ProgressRow>
      </div>
    } else if (csvParsed && isPostingCsvRows && postingFinished) {
      return <div>
        <h3>Your database has been updated! Please close the dialog and refresh to see the change.</h3>
      </div>
    }
  }

  render () {
    return <>
      <div className="bp3-dialog-header">
        <span className="bp3-icon-large bp3-icon-inbox"></span>
        <h4 className="bp3-heading">Loading database</h4>
        <button
          aria-label="Close"
          className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"
          onClick={this.props.closeDialog}
        ></button>
      </div>
      <Container>
        {this.renderFileUpload()}
        {this.renderParsing()}
        {this.renderParsingResult()}
        {this.renderCSVPosting()}
      </Container>
    </>
  }
}

export default DbInit
