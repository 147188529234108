import React from 'react'
import PropTypes from 'prop-types'
import metadataImage from '../imgs/metadata_5.png'
import ceparImage from '../imgs/cepar.png'

/**
 * React Nav component.
 * @return {ReactComponent} Nav component.
 */
const Nav = ({isAuthenticated, handleLogin, toggleLoginModal, handleLogOut, toggleUpdatePasswordModal}) => {

  return (
    <div>
      <nav className="bp3-navbar bp3-dark">
        <div className="bp3-navbar-group bp3-align-left">
          <div className="bp3-navbar-heading ag-navbar-heading">
            <img src={metadataImage} className="ag-navbar-image" alt="metadata"/>
            <img src={ceparImage} className="ag-navbar-image" alt="cepar" />
          </div>
        </div>
        <div className="bp3-navbar-group bp3-align-right">
          <span className="bp3-navbar-divider"/>
          {!isAuthenticated &&
          <button className="bp3-button bp3-minimal bp3-icon-log-in" type="button" onClick={toggleLoginModal}>
            Login
          </button>
          }
          {isAuthenticated &&
          <>
            <button className="bp3-button bp3-minimal bp3-icon-updated" onClick={toggleUpdatePasswordModal}>Change password</button>
            <button className="bp3-button bp3-minimal bp3-icon-log-out" type="button" onClick={handleLogOut}>
              Log out
            </button>
          </>
          }
        </div>
      </nav>
    </div>
  )
}

Nav.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  toggleUpdatePasswordModal: PropTypes.func.isRequired
}
export default Nav
