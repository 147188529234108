import React, { useState } from 'react'
import styled from 'styled-components'
import { InputGroup, Button } from '@blueprintjs/core'

const LoginFormDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 300px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
`
const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 12px;
`
const StyledButton = styled(Button)`
  width: 176px;
`
/**
 * Layout component
 */
const LoginForm = ({handleLogin}) => {

  const [username, setusername] = useState('')
  const [password, setPassword] = useState('')

  return <LoginFormDiv>
    <h2>
      Admin Login
    </h2>
    <StyledInputGroup id="username" placeholder="username" value={username} onChange={evt => setusername(evt.target.value)}/>
    <StyledInputGroup id="password" type="password" placeholder="Password" value={password} onChange={evt => setPassword(evt.target.value)}/>
    <StyledButton type="button" onClick={() => handleLogin({username, password})}>Login</StyledButton>
  </LoginFormDiv>
}

export default LoginForm
