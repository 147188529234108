import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, ControlGroup, InputGroup } from '@blueprintjs/core'

const SearchSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  flex-flow: row nowrap;
  padding: 18px 12px;
  background: #7087983d;
  border-radius: 6px;
  box-shadow: lightgrey;
  margin-bottom: 24px;
`
/**
 * Search function component
 * @return {ReactFunctionalComponent} Section component
 */
const SearchSection = (props) => {
  const {onSearch, searchTerm, updateGlobalSearchField} = props

  return (
    <SearchSectionWrapper>
      <h2>Global search</h2>
      <ControlGroup fill={true} vertical={false}>
        <InputGroup
          value={searchTerm}
          placeholder={'Search any field...'}
          onChange={updateGlobalSearchField}
        />
        <Button icon="search" type="button" onClick={() => onSearch(searchTerm)}></Button>
      </ControlGroup>
    </SearchSectionWrapper>
  )
}

SearchSection.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  updateGlobalSearchField: PropTypes.func.isRequired,
}

export default  React.memo(SearchSection)
