export const SEARCH_FIELD = [
  '---',
  'Variable',
  'Label',
  'Categories',
  'Surveyidentifier',
  'variable_ID',
  'wave',
  'DS_label',
  'DS_desc',
  'DS_or_file',
  'DS_structure',
  'DS_format',
  'DS_link',
  'DS_date',
  'DS_design',
  'DS_type',
  'DS_unit',
  'DS_frame',
  'DS_frame1',
  'DS_date_fw',
  'DS_samp',
  'DS_desc_fw',
  'DS_link2',
  'DS_name',
  'DS_source',
  'DS_type1',
  '_merge',
]

export const DATA_FIELD = [
  'Variable',
  'Label',
  'Categories',
  'Surveyidentifier',
  'variable_ID',
  'wave',
  'DS_label',
  'DS_desc',
  'DS_or_file',
  'DS_structure',
  'DS_format',
  'DS_link',
  'DS_date',
  'DS_design',
  'DS_type',
  'DS_unit',
  'DS_frame',
  'DS_frame1',
  'DS_date_fw',
  'DS_samp',
  'DS_desc_fw',
  'DS_link2',
  'DS_name',
  'DS_source',
  'DS_type1',
  '_merge',
]
