import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Nav from './nav'
import FieldSearchSection from './field-search-section'
import GlobalSearch from './global-search-section'
import Results from './results'
import axios from 'axios'
import { Dialog } from '@blueprintjs/core'
import LoginForm from './login-form'
import cogoToast from 'cogo-toast'
import qs from 'qs'
import * as _ from 'lodash'
import EntryEditForm from './entry-edit-form'
import { DATA_FIELD } from '../extension/constant'
import UpdatePasswordForm from './udpate-password-form'
import Footer from './footer'
import { confirmAlert }from 'react-confirm-alert'
import DbInit from './db-init'

const Body = styled.div`
  display: flex;
  height: calc(100vh - 90px - 30px);
  padding: 2% 2% 1% 2%;
  overflow: auto;
  flex-direction: row;
`
// const SearchBlock = styled.div`
//     display: flex;
//     min-height: 400px;
//     border: 1px solid black
// `

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
`

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 300px;
  margin-left: 50px;
  overflow: auto;
  width: auto;
`

/**
 * Layout component
 */
const Layout = () => {
  const defaultDSl = {
    offset: 0,
    limit: 22,
    query: { match_all: {}}
  }
  const [dsl, setDsl] = useState(defaultDSl)
  const [isAuthenticated, setAuth] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 22
  const [isLoginModalOpen, setModalState] = useState(false)
  const [count, setCount] = useState(0)
  const [results, setResults] = useState([])
  // field search
  const initialValue = [{fieldName: '---', value: ''}]
  const [formValues, setForm] = useState(initialValue)
  const addField = () => setForm(_.concat(formValues, {fieldName: '---', value: ''}))
  const [isUpdatePasswordModalOpen, setUpdatePasswordModalState] = useState(false)

  /**
   * Update field
   * @param {Number} idx - the index of the field.
   * @param {Object} field - field object. {fieldName: xxx, value: xxx}
   */
  const updateField = (idx, field) => {
    setForm(_.clone(_.update(formValues, idx, () => {return field})))
  }
  const resetFields = () => setForm(initialValue)

  // Global search
  const [searchTerm, setSearchTerm] = useState('')
  /**
   * Update field
   * @param {Event} e - the change event
   */
  const updateGlobalSearchField = (e) => {setSearchTerm(e.target.value)}

  const clearSearch = () => {
    setSearchTerm('')
    setResults([])
    setCount(0)
    setCurrentPage(1)
    resetFields()
  }
  /**
   * ToggleLoginModal
   */
  const toggleLoginModal = () => setModalState(!isLoginModalOpen)
  /**
   * Check if user already login.
   */
  const checkSessionStatus = async () => {
    const sessionStatus = await axios.get('/session-status')
    if (sessionStatus.data.authenticated !== isAuthenticated) {
      setAuth(sessionStatus.data.authenticated)
    }
  }
  /**
   * Handle a user submit login.
   * @param {String} username
   * @param {String} password
   */
  const handleLogin = async ({username, password}) => {
    try {
      await axios.post('/login', {username, password})
      cogoToast.success('Login success!')
      setModalState(false)
    } catch (e) {
      cogoToast.warn('Login failed!')
    }
    checkSessionStatus()
  }
  /**
   * Handle submit global search.
   * @param {String} globalSearchTerm - the term to be searched globally.
   */
  const handleGlobalSearch = async (globalSearchTerm) => {
    const elkDsl = {
      offset: 0,
      limit: pageSize,
      query: {
        query_string: {
          query: globalSearchTerm
        }
      }
    }
    // const elkDslStr = qs.stringify(elkDsl)
    // const result = await axios.get(`/api/v1/data/_search?${elkDslStr}`)
    // const count = result.data.hits['total']
    // const results = result.data.hits.hits
    setDsl(elkDsl)
    // setCount(count)
    // setResults(results)
    setCurrentPage(1)
  }
  /**
   * Handle User updates his/her password
   * @param {Object} data {username, password, newPassword, confirmNewPassword}
   * @return {Promise<void>}
   */
  const onUpdatePassword = async (data) => {
    try {
      await axios.post('/api/v1/user/password', data)
      setUpdatePasswordModalState(false)
      cogoToast.success('Password updated.')
    } catch (e) {
      cogoToast.error(e.response.data)
    }

  }
  /**
   * Handle user search by fields.
   * @param {Object} form - [{fieldName: xxx, value: xxx}]
   * Use defualt fuzzy settings: https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-fuzzy-query.html
   */
  const handleFieldSearch = async (form) => {
    // get rid of place holder.
    const cleanedForm = form.filter(item => item.fieldName !== '---')
    const elkDsl = {
      offset: 0,
      limit: pageSize,
      query: {
        bool: {
          must: cleanedForm.map(eachField => {
            return {
              match_phrase: {
                [eachField.fieldName]: eachField.value
              }
            }
          })
        }
      }
    }
    // const elkDslStr = qs.stringify(elkDsl)
    // const result = await axios.get(`/api/v1/data/_search?${elkDslStr}`)
    // const count = result.data.hits['total']
    // const results = result.data.hits.hits
    setDsl(elkDsl)
    setCurrentPage(1)
  }
  const [editingEntryModal, setEditModalEntryModal] = useState({isOpen: false, data: {}})
  /**
   * Handle user click on an row (entry). a modal is called
   */
  const editEntry = (id) => {
    const entryResult = _.find(results, {_id: id}) || {}
    setEditModalEntryModal({isOpen: true, data: entryResult})
  }
  /**
   * Create an new entry.
   */
  const createNewEntry = () => {
    setEditModalEntryModal({isOpen: true, data: {}})
  }
  /**
   * Handle user submits entry editing form
   * @param {Event} evt - event submitting the form.
   * @param id - the id of the document.
   */
  const handleSubmit = (evt, id) => {
    evt.preventDefault()
    const form = evt.target
    const data = DATA_FIELD.reduce((acc, current) => {
      acc[current] = form[current].value
      return acc
    }, {})
    const formValueArray = Object.values(data)
    if (formValueArray.filter(value => value === '' ).length !== formValueArray.length) {
      const requestURI = id ? `/api/v1/data/${id}` : '/api/v1/data'
      const msg = id ? 'The entry has been updated.' : 'New entry has been created.'
      const requestAgent = id ? axios.put : axios.post
      requestAgent(requestURI, data)
        .then(() => {
          cogoToast.success(msg)
          setEditModalEntryModal({isOpen: false, data: {}})
        })
        .catch(e => console.warn(e))
    } else {
      cogoToast.warn('Empty form cannot be submitted.')
    }
  }

  useEffect(() => {
    checkSessionStatus()
  },)

  useEffect(() => {
    (async () => {
      if (dsl) {
        dsl.offset = (currentPage - 1) * pageSize
        if (_.get(dsl, 'query.query_string.query', null) === '') {
          delete dsl.query.query_string
          dsl.query.match_all = {}
        }
        const result = await axios.get(`/api/v1/data/_search?${qs.stringify(dsl)}`)
        const results = result.data.hits.hits
        setResults(results)
        setCount(result.data.hits.total)
        setDsl(dsl)
      }
    })()
  }, [currentPage, dsl])
  /**
   * Handle Logout
   */
  const handleLogOut = () => {
    axios.post('/logout')
      .then(res => {
        if (res.data.logout) {
          setAuth(false)
        }
      })
      .catch(e => {
        console.warn(e)
      })
  }
  /**
   * Handle remove an entry
   * @param {String} id id of the entry to be removed
   */
  const removeEntry = async (id) => {
    confirmAlert({
      title: 'Confirm to remove this item',
      message: 'Are you sure to remove it?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const res = await axios.delete('/api/v1/data/' + id)
              if (res.data._id === id) {
                setResults(_.reject(results, {_id: id})) // Remove the item from the list.
                cogoToast.success('Entry has been removed.')
              }
            } catch (e) {
              cogoToast.error(e.response.data)
            }
          }
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    })
  }
  const [initDbModal, setInitDbModal] = useState(false)

  return <div>
    <Nav
      isAuthenticated={isAuthenticated}
      handleLogin={handleLogin}
      toggleLoginModal={toggleLoginModal}
      toggleUpdatePasswordModal={() => setUpdatePasswordModalState(true)}
      handleLogOut={handleLogOut}
    />
    <Body>
      <LeftDiv>
        <GlobalSearch
          isAuthenticated={isAuthenticated}
          onSearch={handleGlobalSearch}
          searchTerm={searchTerm}
          updateGlobalSearchField={updateGlobalSearchField}
        />
        <FieldSearchSection
          isAuthenticated={isAuthenticated}
          handleSearch={handleFieldSearch}
          addField={addField}
          resetFields={resetFields}
          formValues={formValues}
          setForm={setForm}
          updateField={updateField}
        />
      </LeftDiv>
      <RightDiv>
        <Results
          isAuthenticated={isAuthenticated}
          dsl={qs.stringify(dsl)}
          data={results}
          setInitDbModal={() => setInitDbModal(true)}
          count={count}
          clearSearch={clearSearch}
          editAnEntry={editEntry}
          removeEntry={removeEntry}
          pagination={{
            totalPage: Math.ceil(count / pageSize),
            currentPage: count === 0 ? 0: currentPage,
            onPreviousPage: () => setCurrentPage(currentPage - 1),
            onNextPage: () => setCurrentPage(currentPage + 1)
          }}
          createNewEntry={createNewEntry}
        />
      </RightDiv>
    </Body>
    <Footer/>
    <Dialog
      isOpen={isLoginModalOpen}
      onClose={() => setModalState(false)}
      style={{maxWidth: 300, background: '#394b59', color: 'white'}}
    >
      <LoginForm handleLogin={handleLogin}/>
    </Dialog>
    <Dialog
      isOpen={isUpdatePasswordModalOpen}
      onClose={() => setUpdatePasswordModalState(false)}
      style={{maxWidth: 300, background: '#394b59', color: 'white'}}
    >
      <UpdatePasswordForm onUpdatePassword={onUpdatePassword}/>
    </Dialog>
    <Dialog
      isOpen={editingEntryModal.isOpen}
      onClose={() => setEditModalEntryModal({isOpen: false, data: {}})}
      style={{minWidth: 500, width: 900}}
    >
      <EntryEditForm initData={editingEntryModal.data} handleSubmit={handleSubmit}/>
    </Dialog>
    <Dialog
      isOpen={initDbModal}
      style={{minWidth: 500, width: 500}}
    >
      <DbInit closeDialog={() => setInitDbModal(false)}/>
    </Dialog>
  </div>
}
export default Layout
