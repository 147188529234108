import React from 'react'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  background: white;
  min-height: 36px;
  line-height: 36px;
  border-top: 2px solid rgba(0,0,0,0.1);
  padding: 3px 2px;
  
  button {
    width: 25%;
    //border: 2px solid rgba(0,0,0,0.1);
    background-color:  rgba(0,0,0,0.1);
    color: black;
    font: bold;
  }
  
  button:focus {
    outline: none;
  }
`

export default class Pagination extends React.Component {
  render () {
    const {currentPage, totalPage, onPreviousPage, onNextPage} = this.props.pagination

    return <PaginationWrapper>
      <button onClick={onPreviousPage} disabled={currentPage === 1}>Previous</button>
      <span>{currentPage}/{totalPage}</span>
      <button onClick={onNextPage} disabled={currentPage===totalPage}>Next</button>
    </PaginationWrapper>
  }
}
