import React from 'react'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { DATA_FIELD } from '../extension/constant'
import styled from 'styled-components'

const FormWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
`
const WrappedFormGroup = styled(FormGroup)`
  width: 31%;
  margin-bottom: 12px;
`

const FilledComponent = styled.div`
  width: 31%;
  margin-bottom: 12px;
`
const StyledButton = styled.button`
  float: right;  
`

const Form = styled.form`
  padding: 3% 4%;
`

export default class EntryEditForm extends React.Component {
  render () {
    const {initData, handleSubmit} = this.props
    const {_id, _source} = initData
    const buttonLabel = _id ? 'Update' : 'Create'
    return <Form onSubmit={(evt) => handleSubmit(evt, _id)}>
      <FormWrapper>
        {DATA_FIELD.map(field => {
          const defaultData = _source ? _source[field] : ''
          return <WrappedFormGroup
            disabled={false}
            inline={false}
            label={field}
            labelFor={field}
          >
            <InputGroup id={field} name={field} defaultValue={defaultData} placeholder="Placeholder text"/>
          </WrappedFormGroup>
        })}
        <FilledComponent/>
      </FormWrapper>
      <StyledButton type="submit" className="bp3-button" >{buttonLabel}</StyledButton>
    </Form>
  }
}
