import {Button, Classes, Dialog, Switch} from '@blueprintjs/core'
import {map, size, slice} from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import {DATA_FIELD} from '../extension/constant'

const ColSelectorDiv = styled.div`
  float: right;
  
  .bp3-button {
    height: unset;
  }
`

const Row = styled.div`
  display: flex;
`

const Col = styled.div`
  width: 50%;
`

const Footer = styled.div`
  display: flex;
  margin-left: 16px !important;
  
  .bp3-button {
     margin-left: 4px;
  }
`

const Spacer = styled.div`
  flex-grow: 1;
`

/**
 * Table columns selector
 */
class ColSelector extends Component {
  state = {
    isOpen: false,
    visibleFields: new Set(this.props.visibleFields),
  }

  /**
   * Open the dialog.
   */
  openDialog = () => {
    this.setState({isOpen: true, visibleFields: new Set(this.props.visibleFields)})
  }

  /**
   * Close the dialog.
   */
  closeDialog = () => {
    this.setState({isOpen: false})
  }

  /**
   * Set a filed to visible or hidden
   * @param {string} field - Field name
   * @param {boolean} visible
   */
  setFieldVisible = (field, visible) => {
    const visibleFields = new Set(this.state.visibleFields)
    if (visible) {
      visibleFields.add(field)
    } else {
      visibleFields.delete(field)
    }
    this.setState({visibleFields})
  }

  /**
   * Set all fields as visible
   */
  setAllVisible = () => {
    this.setState({visibleFields: new Set(DATA_FIELD)})
  }

  /**
   * Set all fields as hidden
   */
  setAllHidden = () => {
    this.setState({visibleFields: new Set()})
  }

  /**
   * Apply, then close the dialog
   */
  apply = () => {
    const {onApply} = this.props
    const {visibleFields} = this.state
    onApply(visibleFields)
    this.closeDialog()
  }

  /**
   * Render the checkbox for one field.
   * @param {string} field - Field name
   */
  renderSwitch(field) {
    const {visibleFields} = this.state
    return (
      <Switch
        checked={visibleFields.has(field)}
        label={field}
        large
        onChange={(e) => this.setFieldVisible(field, e.target.checked)}
      />
    )
  }

  /**
   * Render the selector modal dialog.
   * @returns {*}
   */
  renderDialog() {
    const {isOpen} = this.state
    const mid = Math.ceil(size(DATA_FIELD) / 2)
    return (
      <Dialog
        icon="th"
        isOpen={isOpen}
        onClose={this.closeDialog}
        title="Select columns"
      >
        <div className={Classes.DIALOG_BODY}>
          <Row>
            <Col>
              {map(slice(DATA_FIELD, 0, mid), this.renderSwitch.bind(this))}
            </Col>
            <Col>
              {map(slice(DATA_FIELD, mid), this.renderSwitch.bind(this))}
            </Col>
          </Row>
        </div>
        <Footer className={Classes.DIALOG_FOOTER}>
          <Button onClick={this.setAllVisible}>Select All</Button>
          <Button onClick={this.setAllHidden}>Hide All</Button>
          <Spacer/>
          <Button onClick={this.closeDialog}>Cancel</Button>
          <Button intent="primary" onClick={this.apply}>Apply</Button>
        </Footer>
      </Dialog>
    )
  }

  /**
   * Render the component.
   * @returns {*}
   */
  render() {
    return (
      <ColSelectorDiv>
        <Button onClick={this.openDialog}>Select columns</Button>
        {this.renderDialog()}
      </ColSelectorDiv>
    )
  }
}

ColSelector.propTypes = {
  onApply: PropTypes.func.isRequired,
  visibleFields: PropTypes.object.isRequired,
}

export default ColSelector
