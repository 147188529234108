import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SearchForm from './search-form'

const SearchSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  flex-flow: row nowrap;
  padding: 18px 12px;
  background-color: #7087983d;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: bottom 16px center;
  border-radius: 6px;
  box-shadow: lightgrey;
  flex-grow: 1;
  overflow-y: scroll;
  max-height: 622px;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
`
/**
 * Search function component
 * @return {ReactFunctionalComponent} Section component
 */
const FieldSearchSection = (props) => {
  const {handleSearch, formValues, setForm, addField, updateField, resetFields} = props

  return (
    <SearchSectionWrapper>
      <h2>Field search</h2>
      <SearchForm
        form={formValues}
        handleFormUpdate={setForm}
        addField={addField}
        updateField={updateField}
        handleSearch={() => handleSearch(formValues)}
        resetFields={resetFields}
      />
    </SearchSectionWrapper>
  )
}

FieldSearchSection.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  addField: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  formValues: PropTypes.array.isRequired,

}

export default React.memo(FieldSearchSection)
